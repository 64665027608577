.stats-container {
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  display: flex;

  flex-direction: column;
  gap: 30px;

  justify-content: space-around;
  align-items: center;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.stats-divider {
  width: 1px;
  height: 100px;
  background-color: #E5E5E5;

  display: none;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
  .stats-container {
    flex-direction: row;
    gap: 0px;
    transform: translateY(-50%);
  }
}

@media (min-width: 992px) {
  .stats-divider {
    display: block;
  }
}

@media (min-width: 1200px) {

}
