.our-governance-card {
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  text-align: center;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
  .our-governance-card {
    width: 780px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    text-align: left;
  }
}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}
