.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding-top: 10px;
  width: 100%;
  padding-bottom: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.header-logo {
  margin-left: 20px;
}

.nav-icon {
  display: block;
  margin-right: 20px;
}

/* styles.css */
.sticky-header {
  /* position: sticky;
  top: 0; */
  /* width: 100%;
  z-index: 1000; */
  /* background-color: #105F52; */
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

@media (min-width: 576px) {
}

@media (width: 768px) {
  .fixed-header {
    left: -8px;
  }
}

@media (min-width: 992px) {
  .nav-icon {
    display: none;
  }}

@media (min-width: 1200px) {}
