.footer-info {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.footer-container {

  display: flex;

  flex-direction: column;
  gap: 30px;

  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
}

/* style={{
  display: 'flex';

  flexDirection: 'column',
  gap: '30px',

  justifyContent: 'space-between',
  paddingTop: '40px',
  paddingBottom: '40px'
}} */

.footer-background {
  background: url('../assets/Group 83.png') no-repeat;
  background-color: rgba(0, 56, 49, 1);
  background-blend-mode: luminosity;
  background-size: cover;
  
  color: white;
}


@media (min-width: 576px) {

}

@media (min-width: 768px) {
  .footer-container {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .footer-info {
    flex-direction: row;
    gap: 80px;
  }
}

@media (min-width: 1200px) {

}
