.about-us {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  gap: 30px;
}



.about-us-left-container {
  height: inherit;
  flex: 1;
  border: 1px solid whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EDFFF9;
}

.about-us-left-background {
  background: url('../assets/Group 86.png') no-repeat;
  background-color: rgba(237, 255, 249, 1)  ;
  background-blend-mode: luminosity;
  background-size: cover;
}

/* style={{
  height: 'inherit',
  flex: 1,
  border: '1px solid whitesmoke',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#EDFFF9',
}} */


.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  color: black;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #01705D;
  font-weight: 600;
}

.nav-tabs .nav-link {
  border: none;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #01705D;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
  
}

@media (min-width: 992px) {
  .about-us {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1200px) {

}
