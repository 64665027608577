.nav-list-ul {
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-decoration: none;
  color: black;
  margin-top: 10px;
}

.nav-a {
  color: #404040;
  text-decoration: none;
}

.nav-a:hover {
  color: #1b1b1b;
  border-bottom: 1px solid #202020;
}


@media (min-width: 576px) {

}

@media (min-width: 768px) {
  /* .nav-ul {
    display: flex;
  } */
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}
