.nav-ul {
  display: none;
  gap: 40px;
  text-decoration: none;
  color: black;
  margin-top: 10px;
  margin-right: 20px;
}

.nav-a {
  color: #404040;
  text-decoration: none;
}

.nav-a:hover {
  color: #1b1b1b;
  border-bottom: 1px solid #202020;
}

.nav-active {
  border-bottom: 1px solid #008738;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .nav-ul {
    display: flex;
  }

}

@media (min-width: 1200px) {

}
