.legal-status-card {
  border-radius: 10px;
  border: 1px solid #008B71;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  gap: 10px;
}

@media (min-width: 576px) {
  
}

@media (min-width: 768px) {
  
}

@media (min-width: 992px) {
  
}

@media (min-width: 1200px) {

}
