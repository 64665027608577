.carousel-div {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
}

.carousel-text {
  font-size: 25px;
  color: white;
  font-weight: 800;
  text-align: center;
}

.control-dots {
  display: flex;
}

.carousel .slide img {
  border-radius: 15px;
}

@media (min-width: 576px) {}

@media (min-width: 768px) {
  .carousel-div {
    flex-direction: row;
  }

  .carousel-text {
    font-size: 40px;
    line-height: 50px;
    color: white;
    font-weight: 800;
    width: 500px;
    text-align: left;
  }

  .carousel-image {
    width: 656px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
  }
}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}