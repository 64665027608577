.legal-status-card-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding-bottom: 100px;
}

.legal-status-container {
  margin-top: 50px;
  border-radius: 15px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.legal-status-background {
  background: url('../assets/Group 82.png') no-repeat;
  background-color: rgba(0, 56, 49, 1);
  background-blend-mode: luminosity;
  background-size: cover;
}


/* style={{
  backgroundColor: '#105F52',
  border: 'none',
  marginTop: '50px',
  borderRadius: '15px',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}} */

@media (min-width: 576px) {

}

@media (min-width: 768px) {
  .legal-status-card-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 992px) {
  
}

@media (min-width: 1200px) {

}
