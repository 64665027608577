.hero-background-blend {
  background: url('./assets/Mask group.png') no-repeat;
  background-color: rgba(16, 95, 82, 0.85);
  background-blend-mode: luminosity;
  background-size: cover;
  padding-bottom: 20px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .hero-background-blend {
    padding-bottom: 90px;
  }
}

@media (min-width: 992px) {
  .nav-icon {
    display: none;
  }}

@media (min-width: 1200px) {}
